import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import type { Results } from '@prisma/client';
import { DisciplineGroups, Roles } from '@beathletics/api-interfaces';
import { ResultEditFormService } from '../../../shared/result-edit-form.service';
import { doesResultHasDns } from '@beathletics/utils-shared';
import { ResultEditFormBasicComponent } from '../result-edit-form-basic/result-edit-form-basic.component';
import { ResultEditFormHjComponent } from '../result-edit-form-hj/result-edit-form-hj.component';

@Component({
  selector: 'beathletics-result-edit-form-combined',
  templateUrl: './result-edit-form-combined.component.html',
  styleUrls: ['./result-edit-form-combined.component.scss'],
  standalone: true,
  imports: [ResultEditFormBasicComponent, ResultEditFormHjComponent],
})
export class ResultEditFormCombinedComponent {
  private formService = inject(ResultEditFormService);

  forms: {
    type: string;
    form: FormGroup;
    data: Results;
    showWind: boolean;
    resultPrecision: number;
  }[] = [];
  _result: Results | undefined;
  highJumpData = {} as Record<string, any>;

  @Input() set result(result: Results) {
    this._result = result;
    const _events = result?.combinedEvents as any[];
    if (result.combinedEvents && _events.length > 0) {
      for (const ev of _events) {
        const resultPrecision = ('' + ev.eventType?.resultType?.precision).length - 1 || 99;
        const showWind = ev.eventType?.windmode !== 'N';
        if (
          ev.eventType?.resultType?.group !== DisciplineGroups.Saut ||
          ev?.eventType?.shortcode === 'LJ' ||
          ev?.eventType?.shortcode === 'TJ'
        ) {
          this.forms.push({
            type: 'basic',
            form: this.formService.setBasicEditForm(ev, resultPrecision, showWind, true),
            data: ev,
            showWind,
            resultPrecision,
          });
        } else {
          const data = this.formService.setHighJumpEditForm(ev, resultPrecision, true);
          this.highJumpData[ev.name] = data.highJumpResults;
          this.forms.push({
            type: 'high-jump',
            form: data.form,
            data: ev,
            showWind,
            resultPrecision,
          });
        }
      }
    }
  }
  @Input() role: Roles | undefined | null;

  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() resultForm = new EventEmitter<Results>();

  emitCloseDialog() {
    this.closeDialog.emit();
  }

  emitSaveResult(result: Results) {
    const newResult = JSON.parse(JSON.stringify(this._result));
    let eventPoints = 0;
    let hasDns = doesResultHasDns(result);
    for (let i = 0; i < newResult.combinedEvents.length; i++) {
      const _result = newResult.combinedEvents[i];
      if (doesResultHasDns(_result)) hasDns = true;
      if (newResult.combinedEvents[i].name === result.name) {
        newResult.combinedEvents[i] = { ...result };
        if (hasDns) {
          newResult.combinedEvents[i].result.points = 0;
        }
      }
      eventPoints += +newResult.combinedEvents[i]?.result?.points || 0;
    }
    if (hasDns) {
      newResult.pointsTotal = 0;
    } else {
      newResult.pointsTotal = eventPoints;
    }
    this.resultForm.emit(newResult);
  }
}
