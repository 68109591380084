import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedDataService } from '@beathletics/beathletics-data-state';
import { COMPETITION_TYPES } from '@beathletics/utils-shared';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import {
  MatDateRangeInput,
  MatStartDate,
  MatEndDate,
  MatDatepickerToggle,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-competition-data-form',
  templateUrl: './competition-data-form.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatSuffix,
    MatDateRangePicker,
    MatButtonToggleGroup,
    FormsModule,
    MatButtonToggle,
    AsyncPipe,
  ],
})
export class CompetitionDataFormComponent {
  private sharedData = inject(SharedDataService);
  @Input() competitionData:
    | FormGroup<{
        name: FormControl<string>;
        type: FormControl<string | null>;
        venue: FormControl<string | null>;
        startDate: FormControl<string>;
        endDate: FormControl<string | null>;
        organizer: FormControl<string>;
        comment: FormControl<string | null>;
      }>
    | undefined;
  @Output() competitionDataChange = new EventEmitter<FormGroup>();

  @Input() organizer: 'club' | 'federation' = 'club';
  @Output() organizerChange = new EventEmitter<'club' | 'federation'>();

  competitionsTypes = COMPETITION_TYPES;
  clubs$ = this.sharedData.clubs$;
  federations$ = this.sharedData.federations$;
}
