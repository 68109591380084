import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStep, MatStepLabel, MatStepperPrevious, MatStepperNext } from '@angular/material/stepper';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CompetitionDataFormComponent } from '../competition-data-form/competition-data-form.component';
import { MatList, MatListItem } from '@angular/material/list';
import { DatePipe } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'beathletics-competition-creation-dialog',
  templateUrl: './competition-creation-dialog.component.html',
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatStepLabel,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatButton,
    MatProgressSpinner,
    CompetitionDataFormComponent,
    MatStepperPrevious,
    MatStepperNext,
    MatList,
    MatListItem,
    MatDialogClose,
    DatePipe,
  ],
})
export class CompetitionCreationDialogComponent {
  private competitionService = inject(NewCompetitionService);
  private fb = inject(FormBuilder);
  private dialogRef = inject(MatDialogRef<CompetitionCreationDialogComponent>);
  private snackBar = inject(MatSnackBar);

  @ViewChild('competitionCreationStepper') stepperRef!: MatStepper;
  stepperOrientation: StepperOrientation = 'vertical';
  eventNumberValidation = {
    tested: false,
    loading: false,
  };
  competitionOrganizer: 'club' | 'federation' = 'club';
  savingCompetition = false;

  eventNumberForm: FormGroup = this.fb.group({
    eventNumber: ['', Validators.minLength(3)],
    available: ['', Validators.required],
  });

  competitionDataForm = this.fb.group({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    type: ['', Validators.required],
    venue: ['', Validators.required],
    startDate: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    endDate: [''],
    organizer: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    comment: [''],
  });

  testEventNumber() {
    this.eventNumberValidation.tested = true;
    this.eventNumberValidation.loading = true;
    const eventNumber = this.eventNumberForm.get('eventNumber')?.value;
    this.competitionService
      .getIfEventNumberIsAlreadyUsed(eventNumber)
      .pipe(untilDestroyed(this))
      .subscribe((isUsed) => {
        this.eventNumberValidation.loading = false;
        if (!isUsed) {
          this.eventNumberForm.patchValue({ available: !isUsed });
          this.stepperRef.next();
        }
      });
  }

  resetStepperAndForm() {
    this.stepperRef.reset();
    this.eventNumberForm.reset();
    this.competitionDataForm.reset();
  }

  saveCompetition() {
    this.savingCompetition = true;
    const formData = this.competitionDataForm.getRawValue();
    const organizer = {
      abbr: this.competitionDataForm.getRawValue().organizer,
      type: this.competitionOrganizer,
    };
    const newCompetition = {
      eventNumber: this.eventNumberForm.get('eventNumber')?.value,
      name: formData.name,
      type: formData.type,
      startDate: formData.startDate,
      endDate: formData.endDate || formData.startDate,
      comment: formData.comment,
      metadata: { season: formData.venue },
    };
    this.competitionService
      .createNewCompetition(newCompetition, organizer)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (err) => {
          this.dialogRef.close(err);
        },
        complete: () => {
          this.snackBar.open(
            `Compétition ${this.eventNumberForm.get('eventNumber')?.value} correctement créée !`,
            'OK',
            { duration: 5000 },
          );
          this.dialogRef.close();
        },
      });
  }
}
