import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { ECompetitionFeature, EResultFormType, INewTrials } from '@beathletics/api-interfaces';
import { highJumpHeightValidationRegex } from '@beathletics/utils-shared';
import {
  highJumpHeightValidator,
  hightJumpNoDuplicateValidator,
  resultPrecisionValidator,
} from '../../../../../shared/custom-input-validators.directive';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatFormField, MatLabel, MatHint, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'beathletics-competition-result-edit-form',
  templateUrl: './competition-result-edit-form.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatIconButton,
    MatIcon,
    MatHint,
    MatError,
    MatButton,
  ],
})
export class CompetitionResultEditFormComponent {
  @Input() type: EResultFormType | undefined;
  @Input() form!: FormGroup;
  @Input() precision: number | undefined;
  @Input() trials: INewTrials[] | undefined | null;
  @Input() hjTrials:
    | {
        height: string;
        trials: (
          | INewTrials
          | {
              rankingPerf: undefined;
              windSpeed: undefined;
              trialData: undefined;
            }
        )[];
      }[]
    | undefined;
  @Input() newHeightInput!: FormGroup;
  @Input() usedForNewResult = false;
  @Input() isFromCombinedEvents = false;
  @Input() set freezeRank(c: { recalculateRanks: boolean; adminDelete: boolean }) {
    if (this.form) {
      if (c.recalculateRanks) {
        this.form.controls['rank'].disable();
      } else if (!c.adminDelete) {
        this.form.controls['rank'].enable();
      }
    }
  }

  formType = EResultFormType;
  features = Object.values(ECompetitionFeature);
  highJumpValues = ['', '-', 'X', '0'];

  handleAltCode(change: MatSelectChange) {
    if (this.form) {
      if (change.value) {
        this.form.removeControl('height');
      } else {
        this.form.addControl('height', new FormControl(''));
      }
    }
  }

  removeTrial(index: number, hjHeight?: number | string) {
    if (this.form && this.trials) {
      if (this.hjTrials && (hjHeight || hjHeight === 0 || hjHeight === '')) {
        this.hjTrials.splice(index, 1);
        this.form.removeControl('height' + hjHeight);
        this.newHeightInput?.setControl(
          'height',
          new FormControl('', [
            highJumpHeightValidator(highJumpHeightValidationRegex),
            hightJumpNoDuplicateValidator(this.hjTrials.map((h) => h.height)),
          ]),
        );
        for (const n of [0, 1, 2]) {
          this.form.removeControl(`value${hjHeight}.${n}`);
        }
      } else {
        this.trials.splice(index, 1);
        this.form.removeControl('perf' + index);
        if (this.form.get('wind' + index)) {
          this.form.removeControl('wind' + index);
        }
      }
    }
  }

  addNewTrial() {
    if (this.form && this.trials) {
      if (this.type === EResultFormType.HIGH_JUMP && this.newHeightInput && this.hjTrials) {
        const newHeight = this.newHeightInput.value.height;
        const highJumpHeights = this.hjTrials.map((h) => h.height);
        this.form.addControl(
          'height' + newHeight,
          new FormControl(newHeight, [
            highJumpHeightValidator(highJumpHeightValidationRegex),
            hightJumpNoDuplicateValidator(highJumpHeights, newHeight),
          ]),
        );
        for (let i = 0; i < 3; i++) {
          this.form.addControl(`value${newHeight}.${i}`, new FormControl(''));
        }
        this.newHeightInput.setControl(
          'height',
          new FormControl('', [
            highJumpHeightValidator(highJumpHeightValidationRegex),
            hightJumpNoDuplicateValidator(highJumpHeights),
          ]),
        );
        this.hjTrials.push({ height: newHeight, trials: [] });
      } else {
        this.form.addControl(
          'perf' + this.trials.length,
          new FormControl('', resultPrecisionValidator(this.precision || 0)),
        );
        if (this.form.get('wind0')) {
          this.form.addControl('wind' + this.trials.length, new FormControl(''));
        }
        this.trials.push({
          rankingPerf: null,
          windSpeed: null,
          best: false,
          valid: true,
        } as INewTrials);
      }
    }
  }
}
