import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { Event } from '@prisma/client';
import { Store } from '@ngxs/store';
import { UpdateEvent, DeleteEvent } from '@beathletics/beathletics-data-state';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-event-edit-dialog',
  templateUrl: './event-edit-dialog.component.html',
  styleUrls: ['./event-edit-dialog.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton],
})
export class EventEditDialogComponent {
  event = inject<Event>(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<EventEditDialogComponent>>(MatDialogRef);
  private fb = inject(FormBuilder);
  private store = inject(Store);

  eventEditForm: FormGroup = this.setForm();

  setForm() {
    const start_date = new Date(this.event.date_start);
    const end_date = new Date(this.event.date_end);
    return this.fb.group({
      name: [this.event.name],
      year: [start_date.getFullYear()],
      //facility: [this.event.facility.name],
      time: [this.event.start_time],

      start_day: [start_date.getDate()],
      start_month: [start_date.getMonth() + 1],
      end_day: [end_date.getDate()],
      end_month: [end_date.getMonth() + 1],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveEvent() {
    const form = { ...this.eventEditForm.value };
    const editedEvent = { ...this.event };
    editedEvent.name = form.name;
    editedEvent.facility = {
      name: form.facility,
    };
    editedEvent.start_time = form.time;
    editedEvent.date_start = new Date(form.year, form.start_month - 1, form.start_day);
    editedEvent.date_end = new Date(form.year, form.end_month - 1, form.end_day);
    this.store.dispatch(new UpdateEvent(editedEvent));
    this.closeDialog();
  }

  deleteEvent() {
    if (
      this.event.eventNumber &&
      confirm(
        `Êtes-vous sûr de vouloir supprimer définitivement l'évènement
      ${this.event.name} (event number ${this.event.eventNumber}) ?`,
      )
    ) {
      this.store.dispatch(new DeleteEvent(this.event.eventNumber));
      this.closeDialog();
    }
  }
}
