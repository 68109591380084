import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedDialog } from '@beathletics/beathletics-ui';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'beathletics-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  standalone: true,
  imports: [MatButton],
})
export class AlertDialogComponent extends TypedDialog<{ text: string; title: string }, boolean> {
  alert = this.data;

  closeDialog(response: boolean) {
    this.dialogRef.close(response);
  }
}
