import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { IBibData } from '@beathletics/api-interfaces';
import { TranslocoDirective } from '@jsverse/transloco';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { MatButton } from '@angular/material/button';

enum EBibChoiceType {
  ALL = 'all',
  AFFILIATED = 'affiliated',
  UNAFFILIATED = 'unaffiliated',
  CUSTOM = 'custom',
}

@Component({
  selector: 'beathletics-competition-registrations-bib-dialog',
  templateUrl: './competition-registrations-bib-dialog.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatRadioGroup,
    ReactiveFormsModule,
    FormsModule,
    MatRadioButton,
    BeathleticsUiModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CompetitionRegistrationsBibDialogComponent {
  possibilities = Object.values(EBibChoiceType);
  choice?: EBibChoiceType;
  bibList = this.data.bibs.map((b) => ({ label: '' + b.bib, value: b }));
  selectedBibs: IBibData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bibs: IBibData[] },
    private dialogRef: MatDialogRef<CompetitionRegistrationsBibDialogComponent>,
  ) {}

  setChoice(change: MatRadioChange & { value: EBibChoiceType }) {
    if (change.value === EBibChoiceType.ALL) {
      this.selectedBibs = this.bibList.map((b) => b.value);
    } else if (change.value === EBibChoiceType.AFFILIATED) {
      this.selectedBibs = this.data.bibs.filter((b) => b.bib < 9900);
    } else if (change.value === EBibChoiceType.UNAFFILIATED) {
      this.selectedBibs = this.data.bibs.filter((b) => b.bib >= 9900);
    } else {
      return;
    }
  }

  changeSelection(selection: string[]) {
    this.selectedBibs = this.data.bibs.filter((b) => selection.includes('' + b.bib));
  }

  validateSelection() {
    this.dialogRef.close(this.selectedBibs);
  }
}
