import { Component, inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Roles } from '@beathletics/api-interfaces';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'beathletics-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSelect, MatOption],
})
export class UserCreateDialogComponent {
  private fb = inject(FormBuilder);
  private dialogRef = inject<MatDialogRef<UserCreateDialogComponent>>(MatDialogRef);

  createUserForm: FormGroup = this.resetForm();
  roles = Object.values(Roles);

  resetForm() {
    return this.fb.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        //birthdate: ['', Validators.required],
        role: [Roles.User, Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
      },
      { validators: this.validateUser() },
    );
  }

  validateUser(this: UserCreateDialogComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const error = [];

      const firstname = control.get('firstname')?.value;
      const lastname = control.get('lastname')?.value;
      //const birthdate = control.get('birthdate').value;
      const role = control.get('role')?.value;
      const email = control.get('email')?.value;
      const password = control.get('password')?.value;

      if (firstname == '' || firstname == undefined || firstname == null) error.push('firstnameError');
      if (lastname === '' || lastname == undefined || lastname == null) error.push('lastnameError');
      //if (birthdate === "" || birthdate == undefined || birthdate == null) error.push("birthdateError");
      //if (birthdate > Date.now()) error.push("ageError");
      if (!Object.values(Roles).includes(role)) error.push('roleError');
      if (email === '' || email == undefined || email == null) error.push('emailError');
      if (password === '' || password == undefined || password == null) error.push('passwordError');

      const errors = {} as Record<string, boolean>;
      for (let i = 0; i < error.length; i++) {
        errors[error[i]] = true;
      }
      return error.length === 0 ? null : errors;
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
