import { Component, inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

export type FormDefinition<T> = {
  title: string;
  inputs: FormlyFieldConfig[];
  initialValues: T;
  onSubmit: (data: T) => any;
};

@Component({
  selector: 'beathletics-generic-form-dialog',
  standalone: true,
  imports: [FormlyModule, ReactiveFormsModule],
  template: `
    <!-- template of generic form with title and form, use ngx-formly  -->
    <form [formGroup]="form" (ngSubmit)="onSubmit(model)">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
      ></formly-form>
      <button type="submit" class="btn btn-default">Submit</button>
    </form>
  `,
  styles: [],
})
export class GenericFormDialogComponent<T> {
  dialogData = inject(MAT_DIALOG_DATA) as FormDefinition<T>;
  dialogRef = inject(MatDialogRef<typeof this>);
  form = new FormGroup({});
  model = this.dialogData.initialValues;
  fields: FormlyFieldConfig[] = this.dialogData.inputs;

  onSubmit(model: T) {
    this.dialogRef.close(this.dialogData.onSubmit(model));
  }
}
