import { Component, inject } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { KeycloakUser, Roles } from '@beathletics/api-interfaces';
import { AuthService } from '@beathletics/auth';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { map, Observable } from 'rxjs';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-contact-selection-dialog',
  templateUrl: './contact-selection-dialog.component.html',
  standalone: true,
  imports: [
    MatButtonToggleGroup,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggle,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    MatButton,
    MatDialogClose,
    AsyncPipe,
  ],
})
export class ContactSelectionDialogComponent {
  public data = inject(MAT_DIALOG_DATA) as { eventNumber: string };
  private authService = inject(AuthService);
  private competitionService = inject(NewCompetitionService);

  availableRoles = ['secretary', 'ja', 'homologation'];
  contactFunction?: string;
  allUsers$ = this.authService.getAllUsers();
  filteredUsers$?: Observable<KeycloakUser[]>;
  contactToAdd?: KeycloakUser;

  filterContactsByFunction(change: MatButtonToggleChange) {
    this.contactFunction = change.value;
    this.filteredUsers$ = this.allUsers$.pipe(
      map((users) =>
        users
          .filter((user) => (this.contactFunction ? user?.roles?.includes(this.contactFunction) : true))
          .sort((a, b) => (a?.lastName || 'z').localeCompare(b?.lastName || 'z')),
      ),
    );
  }

  selectContact(change: MatSelectChange) {
    this.contactToAdd = change.value;
  }

  assignContact() {
    if (this.contactToAdd && this.contactFunction) {
      const role =
        this.contactFunction === 'secretary'
          ? Roles.Secretary
          : this.contactFunction === 'ja'
            ? Roles.Ja
            : Roles.Homologation;
      this.competitionService.linkOfficialWithCompetition({
        email: this.contactToAdd.email,
        firstName: this.contactToAdd?.firstName || '',
        lastName: this.contactToAdd?.lastName || '',
        role,
      });
    }
  }
}
